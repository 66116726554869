import React, { useEffect, useRef, useState } from "react";
import DashboardFooter from "../Components/Footer/DashboardFooter";
import { Link, useLocation } from "react-router-dom";
import BothMapSvg from "../Components/DashboardComponents/Chart/BothMapSvg";
import "./dashboard.scss";
import Indicators from "./Indicators/IndicatorDropdown";
import { CgFileDocument } from "react-icons/cg";

// Images
import { BiArrowBack, BiRupee } from "react-icons/bi";
import DashboardHeader from "../Components/Header/DashboardHeader";
import { Http } from "../Core/Services/HttpService";
import MyTooltip from "../Components/DashboardComponents/Chart/PopOver";
import { useTranslation } from "../Core/Providers/TranslationProvider";
import { strToNumber, toFixed } from "../assets/js/formbuilder";
import { useStateMounted } from "../Core/Hooks";
import { FaBoxes, FaDownload } from "react-icons/fa";
import SmartMetering from "../Components/DashboardComponents/Chart/SmartMetering";
import EnergyImg from "../images/input-energy.svg";
import InputSolidImg from "../images/input-sold.svg";
import InrCurImg from "../images/revenue.svg";
import CurPerImg from "../images/subsidy.svg";

// Icons
import ATCLoss from "../images/box1.png";
import ARRGap from "../images/box2.png";
import Payable from "../images/box3.png";
import Receivable from "../images/box4.png";
import Discoms from "../images/box5.png";
import SanctionedCost from "../images/box6.png";
import AwardedCost from "../images/Award.svg";
import PMACost from "../images/Project.svg";
import GBSSanctioned from "../images/box7.png";
import GBSRelease from "../images/box8.png";
import ConsumerMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/ConsumerMetering";
import DTMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/DTMetering";
import FeederMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/FeederMetering";
import BoundaryMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/BoundaryMetering";
import LTlinesChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/LTlines";
import HTlinesChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/HTlines";
import DTRsChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/DTRs";
import SubstationsChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/Substations";
import LossReducation from "../Components/DashboardComponents/Chart/LossReducation";

const DashboardStateView = () => {
  const qParams = {
    stateid: new URLSearchParams(document.location.search).get("stateid"),
    indicatorid: new URLSearchParams(document.location.search).get(
      "indicatorid"
    ),
  };
  const [qparams, setQparams] = useState(qParams);
  const location = useLocation();
  const [chartLink, setChartLink] = useState<string>("");
  const [chartLinkLr, setChartLinkLr] = useState<string>("");
  const [__, setLangCode] = useTranslation();
  const [selected, setSelected] = useState("0");
  const [evaluationselected, setEvaluationselected] = useState("2022-23");
  const [isDesktop, setDesktop] = useState(window.innerWidth);
  const [states, setStates] = useState([]);
  const [stateChartPath, setStatechartPath] = useState();
  const [stateDataName, setStateDataName] = useState([]);
  const [stateColor, setStateColor] = useState();
  const [stateViewBox, setStateViewBox] = useState();
  const [stateTransform, setStateTransform] = useState();
  const [discomList, setDiscomList] = useState([{ id: "", discom: "" }]);
  const [physicalProgressLoss, setPhysicalProgressLoss] = useState("0.00");
  // const
  const [stateDiscomValues, setDiscomValues] = useState({
    net_energy_sold: 0.0,
    net_input_energy: "",
    revenue_from_operations: "",
    subsidy_received_billed_in_percentage: "",
    pq_sop_qualified: 0,
    pq_sop_nonqualified: 0,
    pq_sop_under_evalution: 0,
    pq_sop_yet_to_submitted: 0,
    ref_score_grater_75: 0,
    ref_score_grater_60: 0,
    ref_score_less_60: 0,
  });
  const [stateSubsidyReceivedBilled, setStateSubsidyReceivedBilled] =
    useState();

  const [headerInfomativeData, setHeaderInfomativeData] = useState<any>({
    total_sanction_cost: 0,
    total_gbs_sanctioned: 0,
    total_awarded_cost: 0,
    total_pma_cost: 0,
    total_gbs_released: 0,
    total_discoms: 0,
    total_states: 0,
    acc_arr_info: [],
    pqsop: {
      "Qualified": "00",
      "Under Evaluation": "00",
      "To be Re-evaluated": "00",
      "Not Submitted": "00",
    },
    pqsop_previous: {
      "Qualified": "00",
      "Under Evaluation": "00",
      "To be Re-evaluated": "00",
      "Not Submitted": "00",
    },
  });

  const [childFundData, setChildFundData] = useState<any>({
    sm_gbs: 0,
    sm_fund_released: 0,
    lsr_gbs: 0,
    lsr_fund_released: 0,
  });

  const fundChildData = (data: any) => {
    setChildFundData(data);
  };

  const [smartMetering, setSmartMeteringData] = useState<any>({
    // Consumer Metering
    cm: { sanData: 0, awaData: 0, instData: 0 },
    // DT Metering
    dtm: { sanData: 0, awaData: 0, instData: 0 },
    //Feeder Metering
    fm: { sanData: 0, awaData: 0, instData: 0 },
    // Boundary Metering
    bm: { sanData: 0, awaData: 0, instData: 0 },
  });

  const [fundChartData, setFundChartData] = useState<any | null>(null);

  const [infrastructureWorks, setInfrastructureWorks] = useState<any[]>([
    {
      title: "LT lines",
      sanctioned: 0,
      awarded: 0,
      installed: 0,
      unit: "Ckm.",
      type: "LT",
      santion_rate: 0,
      achievment: 0,
      santion_cost: 0,
      sanction_pern: 0,
      award_pern: 0,
      surveyed_pern: 0,
    },
    {
      title: "HT lines",
      sanctioned: 0,
      awarded: 0,
      installed: 0,
      unit: "Ckm.",
      type: "HT",
      santion_rate: 0,
      achievment: 0,
      santion_cost: 0,
      sanction_pern: 0,
      award_pern: 0,
      surveyed_pern: 0,
    },
    {
      title: "DTRs",
      sanctioned: 0,
      awarded: 0,
      installed: 0,
      unit: "Nos.",
      type: "DTR",
      santion_rate: 0,
      achievment: 0,
      santion_cost: 0,
      sanction_pern: 0,
      award_pern: 0,
      surveyed_pern: 0,
    },
    {
      title: "Substations",
      sanctioned: 0,
      awarded: 0,
      installed: 0,
      unit: "Nos.",
      type: "SUB",
      santion_rate: 0,
      achievment: 0,
      santion_cost: 0,
      sanction_pern: 0,
      award_pern: 0,
      surveyed_pern: 0,
    },
  ]);

  const fetchHeaderInfomativeData = async (params: any = {}) => {
    const reqData = {
      stateid: qparams.stateid,
    };
    await Http.get("/apiv1/d-board/infomativeDataHeaderForState", {
      params: reqData,
    })
      .then((res) => {
        if (res._resultflag == "1")
          setHeaderInfomativeData(res.headerInfomativeData);
      })
      .catch((e) => { });
  };

  const fetchSmartMetering = async (params: any = {}) => {
    const cmParams = {
      ...params,
      reportType: "DisStates",
      majorcomponentid: "1,2,3,4",
      subcomp: "0",
      stateid: qparams.stateid,
    };
    Http.get("apiv1/getProgressDetailSm", { params: cmParams })
      .then((res: any) => {
        if (res._resultflag == "1" && res.rows) {
          setSmartMeteringData((prev: any) => {
            return {
              ...prev,
              cm: {
                sanData: strToNumber(res.rows["1"].santion_items),
                awaData: strToNumber(res.rows["1"].award_items),
                instData: strToNumber(
                  res.rows["1"].communicating_metering_cumulative
                ),
              },
              dtm: {
                sanData: strToNumber(res.rows["2"].santion_items),
                awaData: strToNumber(res.rows["2"].award_items),
                instData: strToNumber(
                  res.rows["2"].communicating_metering_cumulative
                ),
              },
              fm: {
                sanData: strToNumber(res.rows["3"].santion_items),
                awaData: strToNumber(res.rows["3"].award_items),
                instData: strToNumber(
                  res.rows["3"].communicating_metering_cumulative
                ),
              },
              bm: {
                sanData: strToNumber(res.rows["4"].santion_items),
                awaData: strToNumber(res.rows["4"].award_items),
                instData: strToNumber(
                  res.rows["4"].communicating_metering_cumulative
                ),
              },
            };
          });
        }
      })
      .catch((e) => { });
  };

  const fetchInfrastructureWorks = async (params: any = {}) => {
    const paramsAll = {
      ...params,
      charttype: "",
      reportType: "DisStates",
      monitoringPar: "1,2,3,4,7,10,11",
      module: "ls",
      stateid: qparams.stateid,
    };
    await Http.get("apiv1/getDashboardReportData", { params: paramsAll })
      .then((res: any) => {
        if (res._resultflag == "1" && res.rows) {
          setInfrastructureWorks((prev: any) => {
            prev[0].sanction_pern = res.rows.lt.sanction_pern;
            prev[0].award_pern = res.rows.lt.award_pern;
            prev[0].surveyed_pern = res.rows.lt.surveyed_pern;
            prev[0].sanctioned = res.rows.lt.santion_items;
            prev[0].awarded = res.rows.lt.award_items;
            prev[0].installed = res.rows.lt.installed_items;
            prev[1].sanction_pern = res.rows.ht.sanction_pern;
            prev[1].award_pern = res.rows.ht.award_pern;
            prev[1].surveyed_pern = res.rows.ht.surveyed_pern;
            prev[1].sanctioned = res.rows.ht.santion_items;
            prev[1].awarded = res.rows.ht.award_items;
            prev[1].installed = res.rows.ht.installed_items;
            prev[2].sanction_pern = res.rows.dtr.sanction_pern;
            prev[2].award_pern = res.rows.dtr.award_pern;
            prev[2].surveyed_pern = res.rows.dtr.surveyed_pern;
            prev[2].sanctioned = res.rows.dtr.santion_items;
            prev[2].awarded = res.rows.dtr.award_items;
            prev[2].installed = res.rows.dtr.installed_items;
            prev[3].sanction_pern = res.rows.sub.sanction_pern;
            prev[3].award_pern = res.rows.sub.award_pern;
            prev[3].surveyed_pern = res.rows.sub.surveyed_pern;
            prev[3].sanctioned = res.rows.sub.santion_items;
            prev[3].awarded = res.rows.sub.award_items;
            prev[3].installed = res.rows.sub.installed_items;
            return [...prev];
          });
          //setPhysicalProgressLoss(toFixed(res.rows.pern, 2));
        }
      })
      .catch((e) => { });
  };

  const fetchPhysicalProgressLoss = async (params: any = {}) => {
    const paramsAll = {
      ...params,
      charttype: "lspercent",
      reportType: "DisStates",
      monitoringPar: "1,2,3,4,7,10,11",
      module: "ls",
      stateid: qparams.stateid,
    };
    await Http.get("apiv1/d-board/progressData", { params: paramsAll })
      .then((res: any) => {
        if (res._resultflag == "1" && res.stateProgressData[0]) {
          setPhysicalProgressLoss(toFixed(res.stateProgressData[0].pern, 2));
        }
      })
      .catch((e) => { });
  };

  const fetchState = async (params: any = {}) => {
    await Http.get("/apiv1/d-board/fundreleasechart", { params })
      .then((res) => {
        setStates(res.stateData.state_name);
        setStatechartPath(res.stateData.detail_state_path);
        setStateDataName(res.stateData.data_name);
        setStateColor(res.stateData.color);
        setStateViewBox(res.stateData.detail_view_box);
        setStateTransform(res.stateData.detail_transform);
        setDiscomList(res.discomList);
        setDiscomValues(res.discomValuesData);
        setStateSubsidyReceivedBilled(res.stateData.subsidy_received_billed);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [pdfonboardLink1, setPdfonBoardLink1] = useStateMounted("#");
  const [pdfonboardLink2, setPdfonBoardLink2] = useStateMounted("#");
  const [smAwardStatusLink, setSmAwardStatusLink] = useStateMounted("#");
  const [lrAwardStatusLink, setLrAwardStatusLink] = useStateMounted("#");
  const [xlsforatcloss, setXlsforatcloss] = useStateMounted("#");
  const [xlsforarrgap, setXlsforarrgap] = useStateMounted("#");
  useEffect(() => {
    fetchState(qparams);
    fetchHeaderInfomativeData();
    fetchSmartMetering({ selected: selected });
    fetchInfrastructureWorks({ selected: selected });
    fetchPhysicalProgressLoss({ selected: selected });
    setFundChartData({
      stateid: qparams.stateid,
      indicatorid: qParams.indicatorid,
    });
    let pdfonboardLink1 =
      process.env.REACT_APP_API_ENDPOINT + "/dashboard/RDSS-PMA-SM.xlsx";
    setPdfonBoardLink1(pdfonboardLink1);
    let pdfonboardLink2 =
      process.env.REACT_APP_API_ENDPOINT + "/dashboard/RDSS-PMA-LR.xlsx";
    setPdfonBoardLink2(pdfonboardLink2);
    let smAwardStatusLink =
      process.env.REACT_APP_API_ENDPOINT +
      "/dashboard/Rdss-award-status-SM.xlsx";
    setSmAwardStatusLink(smAwardStatusLink);
    let lrAwardStatusLink =
      process.env.REACT_APP_API_ENDPOINT +
      "/dashboard/Rdss-award-status-LR.xlsx";
    setLrAwardStatusLink(lrAwardStatusLink);
    let xlsforatcloss =
      process.env.REACT_APP_API_ENDPOINT + "/dashboard/ATC Loss.xlsx";
    setXlsforatcloss(xlsforatcloss);
    let xlsforarrgap =
      process.env.REACT_APP_API_ENDPOINT + "/dashboard/ACS_ARR.xlsx";
    setXlsforarrgap(xlsforarrgap);
  }, [selected, qparams]);

  const changeHandler = (e: any) => {
    setSelected(e.target.value);
  };

  const evaluationChangeHandler = (e: any) => {
    setEvaluationselected(e.target.value);
  };

  useEffect(() => {
    function handleResize() {
      setDesktop(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setChartLink("/sm-financial-progress");
    setChartLinkLr("/lr-financial-progress");
  }, []);

  return (
    <>
      <div className="mainWrap dashboard dbStateView">
        <DashboardHeader />
        <div className="container-fluid">
          <div className="dashboardInner detailedDashboard">
            <div className="heading">
              <div className="row align-items-center">
                <div className="col-md-8 col-lg-8 col-xl-9 float-start">
                  <h2 className="mb-0">
                    <Link to="/dashboard">
                      <em className="arrow">
                        <BiArrowBack />
                        <span className="d-none">Back</span>
                      </em>
                    </Link>
                    {__('RDSS Dashboard')}
                  </h2>
                </div>
                <div className="col-md-3 text-end">
                  <a href="/rdss#Guidelines" style={{ "color": "#f9c267", "fontWeight": "bold", "marginRight": "36px" }}>{__('View Scheme Related Documents')}</a>
                  <a href="/mis-report" style={{ color: "#f9c267", fontWeight: "bold" }} >
                    {__('Detail Report')}
                  </a>
                </div>
              </div>
            </div>
            <div className="row gx-xxl-5">
              <div className="col-xxl-12 col-xl-12">
                <div className="discomSection">
                  <div className="row g-3">
                    <div className="col-xxl-12 col-xl-6 order-2 order-xxl-1">
                      <div className="topStripBox">
                        <div className="customRow">
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link to={xlsforatcloss}>
                                <div className="headModal">
                                  <img
                                    src={ATCLoss}
                                    alt="AT&C Loss"
                                    title="AT&C Loss"
                                  />
                                </div>
                              </Link>
                              <div>
                                <Link
                                  className="text-decoration-none"
                                  to={xlsforatcloss}
                                >
                                  <h4 className="text-dark">
                                    {
                                      headerInfomativeData.acc_arr_info[
                                      "atc_loss"
                                      ]
                                    }
                                  </h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link
                                    className="text-decoration-none"
                                    to={xlsforatcloss}
                                  >
                                    <h6 className="mb-0">{__("AT&C Loss")}</h6>
                                  </Link>
                                  <MyTooltip>
                                    {__('All India AT&C Loss (incl. private DISCOMs) as on FY2021-22')}
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link to={xlsforarrgap}>
                                <div className="headModal">
                                  <img
                                    src={ARRGap}
                                    alt="ACS-ARR Gap"
                                    title="ACS-ARR Gap"
                                  />
                                </div>
                              </Link>
                              <div>
                                <Link
                                  className="text-decoration-none"
                                  to={xlsforarrgap}
                                >
                                  <h4 className="text-dark">
                                    <sub>INR</sub>{" "}
                                    {
                                      headerInfomativeData.acc_arr_info[
                                      "acc_arr"
                                      ]
                                    }
                                    <sub>/kWh</sub>
                                  </h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link
                                    className="text-decoration-none"
                                    to={xlsforarrgap}
                                  >
                                    <h6>{__("ACS-ARR Gap")}</h6>
                                  </Link>
                                  <MyTooltip>
                                    {__('All India ACS-ARR Gap (Cash Adjusted) as on FY2021-22')}
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link
                                className="text-decoration-none"
                                to={
                                  process.env.REACT_APP_API_ENDPOINT +
                                  "/dashboard/Payable Days.xlsx"
                                }
                                target="_blank"
                                title="Payable Days"
                              >
                                <div className="headModal postionTooltip">
                                  <img
                                    src={Payable}
                                    alt="Payable"
                                    title="Payable"
                                  />
                                </div>
                              </Link>
                              <div>
                                <Link
                                  className="text-decoration-none"
                                  to={
                                    process.env.REACT_APP_API_ENDPOINT +
                                    "/dashboard/Payable Days.xlsx"
                                  }
                                  target="_blank"
                                >
                                  <h4 className="text-dark">
                                    {
                                      headerInfomativeData.acc_arr_info[
                                      "payable_day"
                                      ]
                                    }
                                  </h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link
                                    className="text-decoration-none"
                                    to={
                                      process.env.REACT_APP_API_ENDPOINT +
                                      "/dashboard/Payable Days.xlsx"
                                    }
                                    target="_blank"
                                  >
                                    <h6>{__('Payable')}</h6>
                                  </Link>
                                  <MyTooltip>
                                    {__('All India Level data as on FY2021-22')}
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link
                                className="text-decoration-none"
                                to={
                                  process.env.REACT_APP_API_ENDPOINT +
                                  "/dashboard/Receivable Days.xlsx"
                                }
                                target="_blank"
                              >
                                <div className="headModal">
                                  <img
                                    src={Receivable}
                                    alt="Receivable"
                                    title="Receivable"
                                  />
                                </div>
                              </Link>
                              <div>
                                <Link
                                  className="text-decoration-none"
                                  to={
                                    process.env.REACT_APP_API_ENDPOINT +
                                    "/dashboard/Receivable Days.xlsx"
                                  }
                                  target="_blank"
                                >
                                  <h4 className="text-dark">
                                    {
                                      headerInfomativeData.acc_arr_info[
                                      "receivable_day"
                                      ]
                                    }
                                  </h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link
                                    to={
                                      process.env.REACT_APP_API_ENDPOINT +
                                      "/dashboard/Receivable Days.xlsx"
                                    }
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <h6>{__('Receivable')}</h6>
                                  </Link>
                                  <MyTooltip>
                                    {__('All India Level data as on FY2021-22')}
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="customCol-3">
                            <div className="dashBox-1">
                              <Link
                                to={
                                  `/common-table-data?chartfor=state&type=sanctioned_cost&id=` +
                                  qParams.stateid +
                                  `&sectionfor=TableSection`
                                }
                                className="text-decoration-none"
                              >
                                <div className="headModal">
                                  <img
                                    src={SanctionedCost}
                                    alt="Total Sanctioned Cost"
                                    title="Total Sanctioned Cost"
                                  />
                                  <h3 className="text-dark">
                                    INR{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      currency: "INR",
                                      maximumFractionDigits: 2,
                                    }).format(
                                      headerInfomativeData.total_sanction_cost
                                    )}{" "}
                                    Cr
                                  </h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link
                                  to={
                                    `/common-table-data?chartfor=state&type=sanctioned_cost&id=` +
                                    qParams.stateid +
                                    `&sectionfor=TableSection`
                                  }
                                  className="text-decoration-none"
                                >
                                  <h6>{__('Total Sanctioned Cost')}</h6>
                                </Link>
                                <MyTooltip>
                                  {__('Total Sanctioned Cost (incl. Smart Metering, Loss Reduction & PMA)')}
                                </MyTooltip>
                              </span>
                            </div>
                          </div>

                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link
                                to={
                                  `/common-table-data?chartfor=state&type=awarded_cost&id=` +
                                  qParams.stateid +
                                  `&sectionfor=TableSection`
                                }
                                className="text-decoration-none"
                              >
                                <div className="headModal">
                                  <img
                                    src={AwardedCost}
                                    alt="Total Awarded Cost"
                                    title="Total Awarded Cost"
                                  />
                                  <h3 className="text-dark">
                                    INR{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      currency: "INR",
                                      maximumFractionDigits: 2,
                                    }).format(
                                      headerInfomativeData.total_awarded_cost
                                    )}{" "}
                                    Cr
                                  </h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link
                                  to={
                                    `/common-table-data?chartfor=state&type=awarded_cost&id=` +
                                    qParams.stateid +
                                    `&sectionfor=TableSection`
                                  }
                                  className="text-decoration-none"
                                >
                                  <h6>{__('Total Awarded Cost')}</h6>
                                </Link>
                                <MyTooltip>
                                  {__('Total Awarded Cost (incl. Smart Metering, Loss Reduction & PMA)')}
                                </MyTooltip>
                              </span>
                            </div>
                          </div>

                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link
                                to={
                                  `/common-table-data?chartfor=state&type=pma_cost&id=` +
                                  qParams.stateid +
                                  `&sectionfor=TableSection`
                                }
                                className="text-decoration-none"
                              >
                                <div className="headModal">
                                  <img
                                    src={PMACost}
                                    alt="Total PMA Cost"
                                    title="Total PMA Cost"
                                  />
                                  <h3 className="text-dark">
                                    INR{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      currency: "INR",
                                      maximumFractionDigits: 2,
                                    }).format(
                                      headerInfomativeData.total_pma_cost
                                    )}{" "}
                                    Cr
                                  </h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link
                                  to={
                                    `/common-table-data?chartfor=state&type=pma_cost&id=` +
                                    qParams.stateid +
                                    `&sectionfor=TableSection`
                                  }
                                  className="text-decoration-none"
                                >
                                  <h6>{__('Total PMA Cost')}</h6>
                                </Link>
                                <MyTooltip>
                                  {__('Total PMA Cost (incl. Smart Metering, Loss Reduction & PMA)')}
                                </MyTooltip>
                              </span>
                            </div>
                          </div>

                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link
                                to={
                                  `/common-table-data?chartfor=state&type=gbs_sanctioned&id=` +
                                  qParams.stateid +
                                  `&sectionfor=TableSection`
                                }
                                className="text-decoration-none"
                              >
                                <div className="headModal">
                                  <img
                                    src={GBSSanctioned}
                                    alt="Total GBS Sanctioned"
                                    title="Total GBS Sanctioned"
                                  />
                                  <h3 className="text-dark">
                                    INR{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      currency: "INR",
                                      maximumFractionDigits: 2,
                                    }).format(
                                      headerInfomativeData.total_gbs_sanctioned
                                    )}{" "}
                                    Cr
                                  </h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link
                                  to={
                                    `/common-table-data?chartfor=state&type=gbs_sanctioned&id=` +
                                    qParams.stateid +
                                    `&sectionfor=TableSection`
                                  }
                                  className="text-decoration-none"
                                >
                                  <h6>{__('Total GBS Sanctioned')}</h6>
                                </Link>
                                <MyTooltip>
                                  {__('Total GBS Sanctioned (incl. Smart Metering, Loss Reduction & PMA)')}
                                </MyTooltip>
                              </span>
                            </div>
                          </div>
                          <div className="customCol-1 customCol-Last">
                            <div className="dashBox-1">
                              <Link
                                to={
                                  `/common-table-data?chartfor=state&type=gbs_released&id=` +
                                  qParams.stateid +
                                  `&sectionfor=TableSection`
                                }
                                className="text-decoration-none"
                              >
                                <div className="headModal">
                                  <img
                                    src={GBSRelease}
                                    alt="Total GBS Released"
                                    title="Total GBS Released"
                                  />
                                  <h4 className="text-dark">
                                    INR{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      currency: "INR",
                                      maximumFractionDigits: 2,
                                    }).format(
                                      headerInfomativeData.total_gbs_released
                                    )}{" "}
                                    Cr
                                  </h4>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link
                                  to={
                                    `/common-table-data?chartfor=state&type=gbs_released&id=` +
                                    qParams.stateid +
                                    `&sectionfor=TableSection`
                                  }
                                  className="text-decoration-none"
                                >
                                  <h6>{__('Total GBS Released')}</h6>
                                </Link>
                                <MyTooltip>
                                  {__('Total GBS Released (incl. Smart Metering, Loss Reduction & PMA)')}
                                </MyTooltip>
                              </span>
                            </div>
                          </div>

                          <div className="customCol-4">
                            <div className="dashBox-1 text-start">
                              <div className="row">
                                <div className="col-md-8">
                                  <h5>
                                    {__('Evaluation Status')}
                                    {evaluationselected === "2021-22" && (
                                      <Link
                                        to={
                                          process.env.REACT_APP_API_ENDPOINT +
                                          "/dashboard/Evaluation-Status-FY2021-22-V2.xlsx"
                                        }
                                        target="_blank"
                                        title="Evaluation Status"
                                        className="text-dark"
                                      >
                                        <FaDownload />
                                      </Link>
                                    )}
                                    {evaluationselected === "2022-23" && (
                                      <Link
                                        to={
                                          process.env.REACT_APP_API_ENDPOINT +
                                          "/dashboard/Evaluation-Status-FY2022-23-V2.xlsx"
                                        }
                                        target="_blank"
                                        title="Evaluation Status"
                                        className="text-dark"
                                      >
                                        <FaDownload />
                                      </Link>
                                    )}
                                  </h5>
                                </div>
                                <div className="col-md-4 ps-0">
                                  <select
                                    className="form-select form-select-sm"
                                    onChange={evaluationChangeHandler}
                                  >
                                    <option>Select FY</option>
                                    <option value={"2021-22"}>
                                      2021-22
                                    </option>
                                    <option value={"2022-23"} selected>2022-23</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex dashBox-3 mt-4">
                                {evaluationselected === "2021-22" && (
                                  <>
                                    <div className="pqBox w-50 pe-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="Qualified"
                                            >
                                              <h4 className="color-1 me-2 mb-0">
                                                {headerInfomativeData.pqsop_previous[
                                                  "Qualified"
                                                ]
                                                  ? headerInfomativeData.pqsop_previous[
                                                  "Qualified"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("Qualified")}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="To be Re-evaluated"
                                            >
                                              <h4 className="color-2 me-2 mb-0">
                                                {headerInfomativeData.pqsop_previous[
                                                  "To be Re-evaluated"
                                                ]
                                                  ? headerInfomativeData.pqsop_previous[
                                                  "To be Re-evaluated"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("To be Re-evaluated")}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pqBox w-50 ps-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="Under Evaluation"
                                            >
                                              <h4 className="color-3 me-2 mb-0">
                                                {headerInfomativeData.pqsop_previous[
                                                  "Under Evaluation"
                                                ]
                                                  ? headerInfomativeData.pqsop_previous[
                                                  "Under Evaluation"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("Under Evaluation")}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="Not Submitted"
                                            >
                                              <h4 className="color-4 me-2 mb-0">
                                                {headerInfomativeData.pqsop_previous[
                                                  "Not Submitted"
                                                ]
                                                  ? headerInfomativeData.pqsop_previous[
                                                  "Not Submitted"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("Not Submitted")}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {evaluationselected === "2022-23" && (
                                  <>
                                    <div className="pqBox w-50 pe-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="Qualified"
                                            >
                                              <h4 className="color-1 me-2 mb-0">
                                                {headerInfomativeData.pqsop[
                                                  "Qualified"
                                                ]
                                                  ? headerInfomativeData.pqsop[
                                                  "Qualified"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("Qualified")}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="To be Re-evaluated"
                                            >
                                              <h4 className="color-2 me-2 mb-0">
                                                {headerInfomativeData.pqsop[
                                                  "To be Re-evaluated"
                                                ]
                                                  ? headerInfomativeData.pqsop[
                                                  "To be Re-evaluated"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("To be Re-evaluated")}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pqBox w-50 ps-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="Under Evaluation"
                                            >
                                              <h4 className="color-3 me-2 mb-0">
                                                {headerInfomativeData.pqsop[
                                                  "Under Evaluation"
                                                ]
                                                  ? headerInfomativeData.pqsop[
                                                  "Under Evaluation"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("Under Evaluation")}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              className="text-decoration-none text-dark"
                                              to="#"
                                              target="_blank"
                                              title="Not Submitted"
                                            >
                                              <h4 className="color-4 me-2 mb-0">
                                                {headerInfomativeData.pqsop[
                                                  "Not Submitted"
                                                ]
                                                  ? headerInfomativeData.pqsop[
                                                  "Not Submitted"
                                                  ]
                                                  : "00"}
                                              </h4>
                                            </Link>
                                            <h6>{__("Not Submitted")}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-xxl-4 h-100 order-1 order-xxl-2">
                      <div className="discomLeft h-auto">
                        <div className="">
                          <div className="stateDetail d-flex align-items-center justify-content-between mb-3">
                            <div>
                              <span className="d-flex w-100">
                                <h3>{states}</h3>
                                <MyTooltip placement="right">
                                  {__('Source: 12th Integrated Rating Report FY 2022-23')}
                                </MyTooltip>
                              </span>
                              <p>{__('Select DISCOM for detailed view')}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="242"
                                viewBox={stateViewBox}
                                text-anchor="middle"
                              >
                                <path
                                  text-anchor="middle"
                                  fill={stateColor}
                                  stroke="#fff"
                                  stroke-width="1"
                                  transform={stateTransform}
                                  d={stateChartPath}
                                  className="activeState"
                                  data-name={stateDataName}
                                ></path>
                              </svg>
                            </div>
                            <div className="col-md-6">
                              <ul className="position-relative stateViewDiscomList">
                                {discomList.map((value) => {
                                  return (
                                    <li key={value.id}>
                                      <Link
                                        to={`/discom-view-dashboard?stateid=${qparams.stateid}&discomid=${value.id}&indicatorid=${qparams.indicatorid}`}
                                      >
                                        {value.discom}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                          <div className="row discomViewBox stateViewBox">
                            <div className="col-md-6 text-center mt-4">
                              <div className="dashBox align-items-center">
                                <div className="w-75">
                                  <p className="mb-0 text-start">
                                    <strong>
                                      {stateDiscomValues.net_input_energy} MU
                                    </strong>
                                  </p>
                                  <h5 className="text-start">
                                    {__('Net Input Energy')}
                                  </h5>
                                </div>
                                <div className="discIcon">
                                  <img
                                    src={EnergyImg}
                                    className="img-fluid d-block mx-auto"
                                    width="50"
                                    alt="Net Input Energy"
                                    title="Net Input Energy"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 text-center mt-4">
                              <div className="dashBox align-items-center">
                                <div className="w-75">
                                  <p className="mb-0 text-start">
                                    <strong>
                                      {stateDiscomValues.net_energy_sold} MU
                                    </strong>
                                  </p>
                                  <h5 className="text-start">
                                    {__('Net Energy Sold')}
                                  </h5>
                                </div>
                                <div className="discIcon">
                                  <img
                                    src={InputSolidImg}
                                    className="img-fluid d-block mx-auto"
                                    width="50"
                                    alt="Net Input Solid"
                                    title="Net Input Solid"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 text-center mt-3">
                              <div className="dashBox align-items-center">
                                <div className="w-75">
                                  <p className="mb-0 text-start">
                                    <strong>
                                      INR{" "}
                                      {
                                        stateDiscomValues.revenue_from_operations
                                      }{" "}
                                      Cr
                                    </strong>
                                  </p>
                                  <h5 className="text-start">{__('Revenue')}</h5>
                                </div>
                                <div className="discIcon">
                                  <img
                                    src={InrCurImg}
                                    className="img-fluid d-block mx-auto"
                                    width="50"
                                    alt="Revenue"
                                    title="Revenue"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 text-center mt-3">
                              <div className="dashBox align-items-center">
                                <div className="w-75">
                                  <p className="mb-0 text-start">
                                    {stateSubsidyReceivedBilled ? (
                                      <strong>
                                        {stateSubsidyReceivedBilled} %
                                      </strong>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  <h5 className="text-start">
                                    Subsidy Received/ <br /> {__('Subsidy Billed')}
                                  </h5>
                                </div>
                                <div className="discIcon">
                                  <img
                                    src={CurPerImg}
                                    className="img-fluid d-block mx-auto"
                                    width="50"
                                    alt="subsidy Received/Subsidy Billed"
                                    title="subsidy Received/Subsidy Billed"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-xxl-8 order-3">
                      <div className="discomRight">
                        <div className="dashChart">
                          <div className="DiscomSummary">
                            <div className="row">
                              <div className="col-xl-5 position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0 me-3">{__('Smart Metering')}</h4>
                                  <span className="progressTitle">
                                    <BiRupee /> {__('Financial Progress')}
                                    <span className="alert alert-success">
                                      {childFundData
                                        ? toFixed(
                                          (parseInt(
                                            childFundData.sm_fund_released
                                          ) /
                                            parseInt(childFundData.sm_gbs)) *
                                          100,
                                          2
                                        )
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                </div>
                                {fundChartData && (
                                  <SmartMetering
                                    chartdata={fundChartData}
                                    childData={fundChildData}
                                    chartLink={chartLink}
                                    isDiscomView={"true"}
                                    state={{ modal: location }}
                                  />
                                )}
                                <span className="chartMainTitle">
                                  {__('Fund Details (INR cr)')}
                                </span>
                              </div>
                              <div className="col-xl-7 position-relative">
                                <div className="smartMeteringChart">
                                  <Link
                                    to={
                                      `/all-state-physical-progress?chartfor=state&type=sm&id=` +
                                      qparams.stateid +
                                      `&sectionfor=TableSection`
                                    }
                                    state={{ modal: location }}
                                    title="Physical Progress In %"
                                    className="text-decoration-none"
                                  >
                                    <div className="d-flex justify-content-end align-items-center">
                                      <span className="progressTitle">
                                        <FaBoxes className="text-primary me-2" />{" "}
                                        {__('Physical Progress')}{" "}
                                        <span className="alert alert-primary">
                                          {toFixed(
                                            ((parseInt(
                                              smartMetering.cm.instData
                                            ) +
                                              parseInt(
                                                smartMetering.dtm.instData
                                              ) +
                                              parseInt(
                                                smartMetering.fm.instData
                                              ) +
                                              parseInt(
                                                smartMetering.bm.instData
                                              )) /
                                              (parseInt(
                                                smartMetering.cm.sanData
                                              ) +
                                                parseInt(
                                                  smartMetering.dtm.sanData
                                                ) +
                                                parseInt(
                                                  smartMetering.fm.sanData
                                                ) +
                                                parseInt(
                                                  smartMetering.bm.sanData
                                                ))) *
                                            100,
                                            2
                                          )}
                                          %
                                        </span>
                                      </span>
                                    </div>
                                  </Link>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row gx-0">
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress?chartfor=state&type=cm&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                state={{ modal: location }}
                                                title="Consumer Metering(nos.)"
                                              >
                                                {__('Consumer Metering')}{" "}
                                                <sub>(nos.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.cm.sanData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.cm.awaData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Communicating:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.cm.instData
                                                    )
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <ConsumerMeteringChart
                                              sanData={parseInt(
                                                smartMetering.cm.sanData
                                              )}
                                              awaData={parseInt(
                                                smartMetering.cm.awaData
                                              )}
                                              instData={parseInt(
                                                smartMetering.cm.instData
                                              )}
                                              categories="Consumer Metering"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress?chartfor=state&type=dm&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                state={{ modal: location }}
                                                title="DT Metering(nos.)"
                                              >
                                                {__('DT Metering')} <sub>(nos.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.dtm.sanData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.dtm.awaData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Communicating:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.dtm.instData
                                                    )
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <DTMeteringChart
                                              sanData={parseInt(
                                                smartMetering.dtm.sanData
                                              )}
                                              awaData={parseInt(
                                                smartMetering.dtm.awaData
                                              )}
                                              instData={parseInt(
                                                smartMetering.dtm.instData
                                              )}
                                              categories="DT Metering"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress?chartfor=state&type=fm&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                state={{ modal: location }}
                                                title="Feeder Metering(nos.)"
                                              >
                                                {__('Feeder Metering')}{" "}
                                                <sub>(nos.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.fm.sanData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.fm.awaData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Communicating:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.fm.instData
                                                    )
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <FeederMeteringChart
                                              sanData={parseInt(
                                                smartMetering.fm.sanData
                                              )}
                                              awaData={parseInt(
                                                smartMetering.fm.awaData
                                              )}
                                              instData={parseInt(
                                                smartMetering.fm.instData
                                              )}
                                              categories="Feeder Metering"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress?chartfor=state&type=bm&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                state={{ modal: location }}
                                                title="Boundary Metering(nos.)"
                                              >
                                                {__('Boundary Metering')}{" "}
                                                <sub>(nos.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.bm.sanData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.bm.awaData
                                                    )
                                                  )}
                                                </li>
                                                <li>
                                                  {__('Communicating:')}{" "}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    { currency: "INR" }
                                                  ).format(
                                                    parseInt(
                                                      smartMetering.bm.instData
                                                    )
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <BoundaryMeteringChart
                                              sanData={parseInt(
                                                smartMetering.bm.sanData
                                              )}
                                              awaData={parseInt(
                                                smartMetering.bm.awaData
                                              )}
                                              instData={parseInt(
                                                smartMetering.bm.instData
                                              )}
                                              categories="Boundary Metering"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="apexchartsLegendCustom d-flex align-items-center justify-content-center">
                                    <div>
                                      <span className="sanctionedBG"></span>{" "}
                                      {__('Sanctioned')}
                                    </div>
                                    <div>
                                      <span className="gbsBg"></span> {__('Awarded')}
                                    </div>
                                    <div>
                                      <span className="installBg"></span>{" "}
                                      {__('Communicating')}
                                    </div>
                                  </div>
                                </div>
                                <span className="chartMainTitle">
                                  <span>{__('Progress Details (Nos.)')}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="DiscomSummary mt-3">
                            <div className="row">
                              <div className="col-xl-5 position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0 me-3">{__('Loss Reduction')}</h4>
                                  <span className="progressTitle">
                                    <BiRupee /> {__('Financial Progress')}{" "}
                                    <span className="alert alert-success">
                                      {childFundData
                                        ? toFixed(
                                          (parseInt(
                                            childFundData.lsr_fund_released
                                          ) /
                                            parseInt(childFundData.lsr_gbs)) *
                                          100,
                                          2
                                        )
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                </div>
                                {fundChartData && (
                                  <LossReducation
                                    chartdata={fundChartData}
                                    chartLinkLr={chartLinkLr}
                                    isDiscomView={"true"}
                                    state={{ modal: location }}
                                  />
                                )}
                                <span className="chartMainTitle">
                                  {__('Fund Details (INR cr)')}
                                </span>
                              </div>
                              <div className="col-xl-7 position-relative">
                                <div className="smartMeteringChart">
                                  <Link
                                    to={
                                      `/all-state-physical-progress-loss?chartfor=state&type=ls&id=` +
                                      qparams.stateid +
                                      `&sectionfor=TableSection`
                                    }
                                    state={{ modal: location }}
                                    title="Physical Progress In %"
                                    className="text-decoration-none"
                                  >
                                    <div className="d-flex justify-content-end align-items-center">
                                      <span className="progressTitle">
                                        <FaBoxes className="text-primary me-2" />{" "}
                                        {__('Physical Progress')}{" "}
                                        <span className="alert alert-primary">
                                          {physicalProgressLoss}%
                                        </span>
                                      </span>
                                    </div>
                                  </Link>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row gx-0">
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress-loss?chartfor=state&type=LT&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                title="LT lines"
                                                state={{ modal: location }}
                                              >
                                                {__('LT lines')}<sub>(ckm.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {
                                                    infrastructureWorks[0]
                                                      .sanctioned
                                                  }
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {
                                                    infrastructureWorks[0]
                                                      .awarded
                                                  }
                                                </li>
                                                <li>
                                                  {__('Installed:')}{" "}
                                                  {
                                                    infrastructureWorks[0]
                                                      .installed
                                                  }
                                                </li>
                                              </ul>
                                            </div>
                                            {infrastructureWorks[0][
                                              "sanction_pern"
                                            ] > 0 ? (
                                              <LTlinesChart
                                                infraData={
                                                  infrastructureWorks[0]
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress-loss?chartfor=state&type=HT&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                title="HT lines"
                                                state={{ modal: location }}
                                              >
                                                {__('HT lines')}<sub>(ckm.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {
                                                    infrastructureWorks[1]
                                                      .sanctioned
                                                  }
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {
                                                    infrastructureWorks[1]
                                                      .awarded
                                                  }
                                                </li>
                                                <li>
                                                  {__('Installed:')}{" "}
                                                  {
                                                    infrastructureWorks[1]
                                                      .installed
                                                  }
                                                </li>
                                              </ul>
                                            </div>
                                            {infrastructureWorks[1][
                                              "sanction_pern"
                                            ] > 0 ? (
                                              <HTlinesChart
                                                infraData={
                                                  infrastructureWorks[1]
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress-loss?chartfor=state&type=DTR&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                title="DTRs"
                                                state={{ modal: location }}
                                              >
                                                {__('DTRs')}<sub>(nos.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {
                                                    infrastructureWorks[2]
                                                      .sanctioned
                                                  }
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {
                                                    infrastructureWorks[2]
                                                      .awarded
                                                  }
                                                </li>
                                                <li>
                                                  {__('Installed:')}{" "}
                                                  {
                                                    infrastructureWorks[2]
                                                      .installed
                                                  }
                                                </li>
                                              </ul>
                                            </div>
                                            {infrastructureWorks[2][
                                              "sanction_pern"
                                            ] > 0 ? (
                                              <DTRsChart
                                                infraData={
                                                  infrastructureWorks[2]
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link
                                                to={
                                                  `/all-state-progress-loss?chartfor=state&type=SUB&id=` +
                                                  qparams.stateid +
                                                  `&sectionfor=TableSection`
                                                }
                                                title="Substations"
                                                state={{ modal: location }}
                                              >
                                                {__('Substations')}<sub>(nos.)</sub>
                                              </Link>
                                              <ul>
                                                <li>
                                                  {__('Sanctioned:')}{" "}
                                                  {
                                                    infrastructureWorks[3]
                                                      .sanctioned
                                                  }
                                                </li>
                                                <li>
                                                  {__('Awarded:')}{" "}
                                                  {
                                                    infrastructureWorks[3]
                                                      .awarded
                                                  }
                                                </li>
                                                <li>
                                                  {__('Installed:')}{" "}
                                                  {
                                                    infrastructureWorks[3]
                                                      .installed
                                                  }
                                                </li>
                                              </ul>
                                            </div>
                                            {infrastructureWorks[3][
                                              "sanction_pern"
                                            ] > 0 ? (
                                              <SubstationsChart
                                                infraData={
                                                  infrastructureWorks[3]
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="apexchartsLegendCustom d-flex align-items-center justify-content-center">
                                    <div>
                                      <span className="sanctionedBG"></span>{" "}
                                      {__('Sanctioned')}
                                    </div>
                                    <div>
                                      <span className="gbsBg"></span> {__('Awarded')}
                                    </div>
                                    <div>
                                      <span className="installBg"></span>{" "}
                                      {__('Installed')}
                                    </div>
                                  </div>
                                </div>
                                <span className="chartMainTitle">
                                  <span>{__('Progress Details (%)')}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashboardFooter remove="d-none" />
      </div>
    </>
  );
};

export default DashboardStateView;
