import React, { Component, useState } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "../css/style.scss";
import "./var.scss";
import { Button, Col, Form } from 'react-bootstrap';
import { Row } from 'reactstrap';
import ContactForm from '../Components/ContactForm/ContactForm';


function ContactUs() {
    const [validated, setValidated] = useState(false);
    
    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
    
        setValidated(true);
    };
    return (
        <>
            <Header />
            <InnerTemplate sublink={false} PageTitle="Contact Us" />
            <div className='main'>
                <div className='container'>
                    <div className='AboutSection mt-4'>
                        <div className='row'>
                            <div className='col-md-4 d-flex'>
                                <div className='contactDiv'>
                                    <h4>REC</h4>
                                    <h6><b>Corporate Office: </b></h6>
                                    <p>Plot No. I-4, Sector 29, <br /> Near IFFCO Chowk Metro Station, Gurugram, Haryana 122001</p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex'>
                                <div className='contactDiv'>
                                    <h4>REC</h4>
                                    <h6><b>Registered Office: </b></h6>
                                    <p>Core- 4, SCOPE Complex, 7, Lodhi Road, CGO Complex, Pragati Vihar, New Delhi, Delhi 110003</p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex'>
                                <div className='contactDiv'>
                                    <h4>PFC</h4>
                                    <h6><b>Registered Office: </b></h6>
                                    <p>Power Finance Corporation Ltd. 'Urjanidhi', 1, Barakhamba Lane, Connaught Place New Delhi-110 001 Phone No.91-11-23456000</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-6'>
                                <div className='mainMap'>
                                    <iframe width="600" height="600" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Plot%20No.%20I-4,%20Sector%2029,%20Near%20IFFCO%20Chowk%20Metro%20Station,%20Gurugram+(REC%20Limited)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='contactDiv'>
                                    <h4>Contact Us</h4>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs;