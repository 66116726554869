import React, { useState } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import './contactform.scss';

const ContactForm: React.FC = () => {
  const [validated, setValidated] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    feedback: '',
  });
  const [message, setMessage] = useState<{ type: 'success' | 'danger'; text: string } | null>(null);

  React.useEffect(() => {
    loadCaptchaEnginge(6); // Generates a 6-character captcha
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validateCaptcha(captchaInput)) {
      setMessage({ type: 'danger', text: 'Invalid CAPTCHA. Please try again.' });
      return;
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    try {
      const response = await axios.post('http://localhost/contact-form/submit.php', formData);
      if (response.data.success) {
        setMessage({ type: 'success', text: 'Message sent successfully!' });
        setFormData({ firstName: '', lastName: '', email: '', contactNumber: '', feedback: '' });
        setValidated(false);
      }
    } catch (error) {
      setMessage({ type: 'danger', text: 'Failed to send message. Please try again.' });
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {message && <Alert variant={message.type}>{message.text}</Alert>}

      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter Your First Name"
          />
          <Form.Control.Feedback type="invalid">Enter First Name</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Enter Your Last Name"
          />
          <Form.Control.Feedback type="invalid">Enter Last Name</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter Your Email"
          />
          <Form.Control.Feedback type="invalid">Enter Valid Email</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridContactNumber">
          <Form.Label>Contact Number</Form.Label>
          <Form.Control
            required
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            placeholder="Enter Your Contact Number"
          />
          <Form.Control.Feedback type="invalid">Enter Contact Number</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group className="mb-2" controlId="formGridFeedback">
        <Form.Label>Feedback / Query</Form.Label>
        <Form.Control
          required
          as="textarea"
          rows={3}
          name="feedback"
          value={formData.feedback}
          onChange={handleChange}
          placeholder="Feedback / Query"
        />
        <Form.Control.Feedback type="invalid">Enter Feedback / Query</Form.Control.Feedback>
      </Form.Group>


    <Form.Group className='d-flex gap-2 align-items-end'>
        <Form.Group className='col-md-4' controlId="formGridCaptcha">
            <Form.Label>Verify CAPTCHA</Form.Label>
            <LoadCanvasTemplate />
        </Form.Group>
        <Form.Control
        required
        type="text"
        placeholder="Enter CAPTCHA"
        value={captchaInput}
        onChange={(e) => setCaptchaInput(e.target.value)}
        />
        <Button variant="primary" className="d-block ms-auto mt-0 submitBtn" type="submit">
            Submit
        </Button>
    </Form.Group>
    </Form>
  );
};

export default ContactForm;
