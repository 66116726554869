import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

const FontResize: React.FC = () => {
  const DEFAULT_FONT_SIZE = 16; // Default font size in pixels
  const MIN_FONT_SIZE = 14; // Minimum font size
  const MAX_FONT_SIZE = 18; // Maximum font size
  const STORAGE_KEY = "fontSize"; // Key for localStorage

  // Load font size from localStorage or use default
  const [fontSize, setFontSize] = useState<number>(
    Number(localStorage.getItem(STORAGE_KEY)) || DEFAULT_FONT_SIZE
  );

  // Apply the font size on component mount
  useLayoutEffect(() => {
    document.documentElement.style.fontSize = `${fontSize}px`;
  }, [fontSize]);

  // Function to update font size
  const updateFontSize = (newSize: number) => {
    if (newSize >= MIN_FONT_SIZE && newSize <= MAX_FONT_SIZE) {
      setFontSize(newSize);
      document.documentElement.style.fontSize = `${newSize}px`;
      localStorage.setItem(STORAGE_KEY, newSize.toString()); // Store in localStorage
    }
  };

  return (
    <li>
      <div className="d-flex gap-2">
        <Link to="javascript:void(0);" onClick={() => updateFontSize(fontSize - 1)}>
          A-
        </Link>
        <Link to="javascript:void(0);" onClick={() => updateFontSize(DEFAULT_FONT_SIZE)}>
          A
        </Link>
        <Link to="javascript:void(0);" onClick={() => updateFontSize(fontSize + 1)}>
          A+
        </Link>
      </div>
    </li>
  );
};

export default FontResize;
