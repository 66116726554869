import React, { Component } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "../css/style.scss";
import "./var.scss";

export default class About extends Component {
    render() {
        return (
            <div className='fixWhiteSpace'>
                <div>
                    <Header />
                    <InnerTemplate sublink={false} PageTitle="About Us" />
                    <div className='main'>
                        <div className='container'>
                            <div className='AboutSection mt-4'>
                                <h5>The integrated digital platform has been designed and developed by the Ministry of Power, REC and Power Finance Corporation.</h5>
                                <ul>
                                    <li><b>Ministry of Power</b>: The MoP is primarily responsible for the development of electrical energy in the country. It is concerned with perspective planning, policy formulation, processing of projects for investment decision, monitoring of the implementation of power projects, training and manpower development and the administration and enactment of legislation with respect to thermal, hydro power generation, transmission, and distribution. The Ministry is responsible for the Administration of the Electricity Act, 2003, and the Energy Conservation Act, 2001 and can undertake amendments to these Acts, as may be necessary from time to time, in conformity with the Government's policy objectives.</li>
                                    <li><b>Rural Electrification Corporation</b>: REC is a Maharatna Central Public Sector Undertaking under the Ministry of Power. It is a leading infrastructure finance company, with a net worth of over ₹50,000 crores (as on 31st March, 2022). REC’s business activities involve financing projects across the power sector value chain (generation, transmission, or distribution). REC provides financial assistance to state electricity boards, state governments, central/state power utilities, independent power producers, rural electric cooperatives, and private sector utilities.</li>
                                    <li><b>Power Finance Corporation</b>: Incorporated on July 16th, 1986, PFC is a Schedule-A Maharatna Central Public Sector Undertaking and is a leading Non-Banking Financial Corporation. PFC is under the administrative control of the Ministry of Power and was classified as an Infrastructure Finance Company by the RBI on 28th July,2010.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
