import React, { Component } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "./var.scss";

export default class WebisteInformationManager extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Website Information Manager" />
                <div className='main'>
                    <div className='container'>
                        <div className='wimPage my-5'>
                            <div className="webInfo">
                                <h2></h2>
                                <h3></h3>
                                <p><span>Address:</span></p>
                                <p><span>Contact no:</span></p>
                                <p className="mb-0"><span>Email:</span> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
