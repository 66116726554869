import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Function to set a cookie manually
const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/; SameSite=Lax`;
};

// Function to get a cookie manually
const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

const Theme: React.FC = () => {
  const location = useLocation();
  const [theme, setTheme] = useState<string>("light-mode");

  // Function to apply the theme
  const applyTheme = (mode: string) => {
    setTimeout(() => {
      document.body.classList.remove("dark-mode", "light-mode"); // Remove previous theme
      document.body.classList.add(mode); // Apply new theme
    }, 0);

    setTheme(mode);
    setCookie("theme", mode, 30); // Store in cookies for 30 days
    localStorage.setItem("theme", mode); // Backup in localStorage
  };

  // Load theme BEFORE render using useLayoutEffect
  useLayoutEffect(() => {
    const savedTheme = getCookie("theme") || localStorage.getItem("theme") || "light-mode";
    applyTheme(savedTheme);
  }, []);

  // Detect if modal is open based on the route state
  useEffect(() => {
    if (location.state?.modal) {
      applyTheme("dark-mode"); // Automatically switch to dark mode
    } else {
      const savedTheme = getCookie("theme") || localStorage.getItem("theme") || "light-mode";
      applyTheme(savedTheme); // Restore previous theme when modal is closed
    }
  }, [location]);

  return (
    <li>
      <div className="d-flex gap-1">
        <Link className="lightMode" to="javascript:void(0);" onClick={() => applyTheme("light-mode")}>
          Light Mode
        </Link>
        <Link className="darkMode" to="javascript:void(0);" onClick={() => applyTheme("dark-mode")}>
          Dark Mode
        </Link>
      </div>
    </li>
  );
};

export default Theme;
