import React, { useEffect, useRef, useState } from "react";
import "./Footer.scss";
import { Link, useLocation } from "react-router-dom";
import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin } from "react-icons/ti";
import { IoLogoInstagram } from "react-icons/io";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../../node_modules/swiper/swiper.scss";
import { Navigation } from "swiper";

// Imges
import IndiaGov from '../../../../images/india-gov.png';
import MyGov from '../../../../images/mygov.png';
import AKAM from '../../../../images/akam.png';
import G20 from '../../../../images/g20.png';
import PMIndia from '../../../../images/pmindia-logo.png';
import MinisryPower from '../../../../images/ministry-fo-pwer.png';
import MakeInIndia from '../../../../images/makeinindia.png';
import TwitterIcon from "../../../../assets/ImageCompo/TwitterIcon";
import { Modal, Button } from "react-bootstrap";
import { BsArrowUp } from "react-icons/bs";

function Footer(props: any) {

    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const [showBackToTop, setShowBackToTop] = useState(false);

    const [lastUpdateDate, setLastUpdateDate] = useState('');
    const fetchLastUpdateDate = () => {
        const currentDate = new Date();
        setLastUpdateDate(currentDate.toDateString());
    };
    useEffect(() => {
        fetchLastUpdateDate();
    }, []);

    useEffect(() => {
    }, [location]);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');

    const handleClick = (e: any) => {
        const { href } = e.currentTarget;

        // Check if the clicked URL is a third-party URL
        if (isThirdPartyUrl(href)) {
            e.preventDefault(); // Prevent default navigation behavior
            setRedirectUrl(href);
            setShowConfirmation(true);
        }
    }

    const handleConfirm = () => {
        setShowConfirmation(false);
        if (redirectUrl) {
            window.open(redirectUrl, '_blank');
        }
    }

    const handleCancel = () => {
        setShowConfirmation(false);
    }

    // Function to check if a URL is third-party
    const isThirdPartyUrl = (url: any) => {
        const currentHostname = window.location.hostname;
        const urlHostname = new URL(url).hostname;

        return currentHostname !== urlHostname;
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 50) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const Fade = require('react-reveal/Fade');
    return (
        <>
            <div id="footer-content">
                <section className={'logoSection ' + props.remove}>
                    <div className="container">
                        <Fade bottom cascade>
                            <Swiper
                                spaceBetween={0}
                                modules={[Navigation]}
                                navigation={{
                                    prevEl: '.press-prev',
                                    nextEl: '.press-next',
                                }}
                                breakpoints={{
                                    1200: {
                                        slidesPerView: 7,
                                    },
                                    991: {
                                        slidesPerView: 6,
                                    },
                                    768: {
                                        slidesPerView: 5,
                                    },
                                    575: {
                                        slidesPerView: 4,
                                    },
                                    360: {
                                        slidesPerView: 1,
                                    }

                                }}
                                className="logoSwiper"
                            >
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://www.india.gov.in/" rel="noreferrer" title="India Gov"><img alt="India Gov" title="India Gov" className="img-fluid" src={IndiaGov} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://www.mygov.in/" rel="noreferrer" title="My Gov"><img alt="My Gov" title="My Gov" className="img-fluid" src={MyGov} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://amritmahotsav.nic.in/" rel="noreferrer" title="Amrit Mahotsav"><img alt="Amrit Mahotsav" title="Amrit Mahotsav" className="img-fluid" src={AKAM} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://g20.mygov.in/" rel="noreferrer" title="G20"><img alt="G20" title="G20" className="img-fluid" src={G20} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://www.pmindia.gov.in/en/" rel="noreferrer" title="PM India"><img alt="PM India" title="PM India" className="img-fluid" src={PMIndia} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://powermin.gov.in/" rel="noreferrer" title="Power Ministry"><img alt="Power Ministry" title="Power Ministry" className="img-fluid" src={MinisryPower} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" onClick={handleClick} href="https://www.makeinindia.com/" rel="noreferrer" title="Make In India"><img alt="Make in India" title="Make In India" className="img-fluid" src={MakeInIndia} /></a></div></SwiperSlide>
                            </Swiper>
                        </Fade>
                    </div>
                    <Modal show={showConfirmation} onHide={handleCancel} backdrop="static" keyboard={false} size="sm" centered className="smokeModal">
                        <Modal.Body>
                            <h6 className="text-center mb-4">External website that opens in a new window. <br /> Click Yes to proceed.</h6>
                            <div className="d-flex justify-content-center">
                                <Button variant="dark" className="px-3 py-1 me-3" onClick={handleConfirm}>Yes</Button>
                                <Button variant="secondary" className="px-3 py-1" onClick={handleCancel}>No</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </section>
                <footer>
                    <div className="container">
                        <div className="footerInner d-flex align-items-center justify-content-between">
                            <div className="copyRight">
                                <ul>
                                    <li><Link to="/terms-conditions" title="Terms & Conditions">Terms & Conditions</Link></li>
                                    <li><Link to="/website-policy" title="Website Policy">Website Policy</Link></li>
                                    <li><Link to="/copyright-policy" title="Copyright Policy">Copyright Policy</Link></li>
                                    <li><Link to="/disclaimer" title="Disclaimer">Disclaimer</Link></li>
                                    <li><Link to="/help" title="Help">Help</Link></li>
                                    {/* <li><Link to="/website-information-manager" title="Website Information Manager">Website Information Manager</Link></li> */}
                                </ul>
                                <p className="mb-0">Copyright © {currentYear} REC Ltd., PFC Ltd., Ministry of Power, Government of India. All rights reserved</p>
                            </div>
                            <div className="socialSection">
                                <ul className="social">
                                    <li><Link target="_blank" onClick={handleClick} to="https://www.facebook.com/RECLIndia" title="Facebook"><span className="d-none">Facebook</span><TiSocialFacebook /></Link></li>
                                    <li><Link target="_blank" onClick={handleClick} to="https://twitter.com/reclindia?lang=en" title="Twitter"><span className="d-none">Twitter</span><TwitterIcon style={{ width: 18 }} /></Link></li>
                                    <li><Link target="_blank" onClick={handleClick} to="https://www.linkedin.com/company/reclindia/" title="Linkedin"><span className="d-none">Linkedin</span><TiSocialLinkedin /></Link></li>
                                    <li><Link target="_blank" onClick={handleClick} to="https://www.instagram.com/reclindia/?hl=en" title="Instagram"><span className="d-none">Instagram</span><IoLogoInstagram /></Link></li>
                                </ul>
                            </div>
                            <div className="visitor text-end">
                                {/* <p className="mb-0">Last Updated: {lastUpdateDate}</p> */}
                                <p className="mb-0">Last Updated: {process.env.REACT_APP_UPDATED_DATE}</p>
                                {/* <p className="mb-0">Visitors: 5,68,920</p> */}
                                <p className="mb-0">Version: {process.env.REACT_APP_VERSION}</p>
                            </div>
                        </div>
                    </div>
                </footer>
                <div id="footer-spinner" className="spinner-container d-none">
                    <div className="spinner-outer">
                        <div className="loading-spinner"></div>
                    </div>
                </div>
            </div>
            {showBackToTop && (
                <button className="back-to-top" onClick={scrollToTop}>
                    <span className="d-none">Back to Top</span><BsArrowUp />
                </button>
            )}
        </>
    );
}

export default Footer;