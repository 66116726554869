import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import './Header.scss';
import PowerLogo from '../../../../images/power-logo.png';
import RecLogo from '../../../../images/rec-logo.png';
import PfcLogo from '../../../../images/pfc-logo.png';
import RecLogoDark from '../../../../images/rec-logo-dark.png';
import PfcLogoDark from '../../../../images/pfc-logo-dark.png';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../Core/Providers/AuthProvider';
import { Storage } from '../../../../Core/Services/StorageService';
import Theme from './theme';
import FontResize from './FontResize';
// import Timer from '../../../../Core/Components/Timer';

function Header() {

    const auth = useAuth();
    let navigage = useNavigate();
    const [roleid, setRoleid] = useState<any>();
    const [username, setUsername] = useState<any>();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
    });

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const handleClickScroll = () => {
        const element = document.getElementById('schemeSection');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
        
    const logout = () => {
        auth.logout()
            .then(() => {
                Storage.delete('roleid');
                Storage.delete('username');
                window.location.replace('/sign-in');
            });
    };

    return (
        <header>
            <div className='topHeader'>
                <div className='container'>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-md-12 col-lg-5'>
                            <ul className='list-unstyled'>
                                <li><Link to="/faq">FAQ's</Link></li>
                                <li><Link to="#">Sitemap</Link></li>
                                {
                                    username ? <li>Welcome! {username}</li> : null
                                }
                            </ul>
                        </div>
                        <div className='col-md-12 col-lg-7 text-end'>
                            <ul className='list-unstyled'>
                                <li><Link to="#" onClick={handleClickScroll}>Skip to Main Content</Link></li>
                                <li><Link to="/screen-reader-access">Screen Reader Access</Link></li>
                                <FontResize/>
                                <Theme />
                                {/* <li><Link to="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><BsSearch /><span className='d-none'>Search</span></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isScrolled ? "headerSticky scrolled" : "headerSticky"}>
                <div className='container'>
                    <div className='mainHeader'>
                        <div className='logo d-flex'>
                            <h1><Link to="/"><img src={PowerLogo} width='170' alt='Logo' className="img-fluid mainLogo" /></Link></h1>
                            <a href="https://recindia.nic.in/" className='normal-theme' target="_blank"><img src={RecLogo} width='140' alt='REC India' className="img-fluid me-2 recLogo" /></a>
                            <a href="https://www.pfcindia.com/" className='normal-theme' target="_blank" ><img src={PfcLogo} width='80' alt='PFC India' className="img-fluid pfcLogo" /></a>
                            <a href="https://recindia.nic.in/" className='dark-theme' target="_blank"><img src={RecLogoDark} width='140' alt='REC India' className="img-fluid me-2 recLogo" /></a>
                            <a href="https://www.pfcindia.com/" className='dark-theme' target="_blank" ><img src={PfcLogoDark} width='80' alt='PFC India' className="img-fluid pfcLogo" /></a>
                        </div>
                        <div className='navMenu d-flex align-items-center'>
                            <div className='mainNav'>
                                <nav className="navbar navbar-expand-xl">
                                    <div className="container-fluid">
                                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="offcanvas offcanvas-start" id="navbarSupportedContent">
                                            <button type="button" className="btn-close d-xl-none text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                                <li className="nav-item">
                                                    <Link className="nav-link active" title='Home' aria-current="page" to="/"><AiOutlineHome /></Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/about-us">About Us</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/contact-us">Contact us</Link>
                                                </li>
                                                {
                                                    username ?
                                                        <>
                                                            <li className="nav-item">
                                                                <Link className="nav-link" to="/change-password">Change Password</Link>&nbsp;
                                                            </li>
                                                            <li className="nav-item">
                                                                <div className='loginBtn'>
                                                                    <Link className="nav-link" onClick={() => logout()} to="#">Logout</Link>
                                                                </div>
                                                            </li>
                                                        </>
                                                        :
                                                        <li className="nav-item">
                                                            <div className='loginBtn'>
                                                                <Link className="nav-link" to="/sign-in">Sign in</Link>
                                                            </div>
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            {
                                username ?
                                    <>
                                        {/* <Link className="nav-link" to="/change-password">Change Password</Link>&nbsp; */}
                                        {/* <div className='loginBtn'>
                                            <Link className="nav-link" onClick={() => logout()} to="#">Logout</Link>
                                        </div> */}

                                        {/* <div className='loginBtn'>
                                            <div className='userProfile'>
                                                <div className='userIcon'><img src={ManImg} width="40" height="40" className="rounded-circle"/></div>
                                                <div className="userName">
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            Abhishek Shah
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#">Edit Profile</Dropdown.Item>
                                                            <Dropdown.Item href="#">Bank Details</Dropdown.Item>
                                                            <Dropdown.Item href="#">Discom Details</Dropdown.Item>
                                                            <Dropdown.Item href="#" onClick={() => logout()}>Logout</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <span>DISCOM-1</span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </>
                                    :
                                    <>
                                        {/* <div className='loginBtn'>
                                        <Link className="nav-link" to="/sign-in">Sign in</Link>
                                    </div> */}
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-top searchSlide" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasTopLabel" className="offcanvas-title">Search</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                
                <div className="offcanvas-body">
                    <div id="cover" className="offCanvasSearch d-flex align-items-center">
                        <form action="">
                            <div className="search-box">
                                <label htmlFor="searchBox">Search</label>
                                <input className="search-input" id='searchBox' type="text" placeholder="Search something.." />
                                <button className="search-btn">
                                    <BsSearch /> <span className='d-none'>Search</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </header>
    );
}

export default Header;
function logout(arg0: () => void) {
    throw new Error('Function not implemented.');
}

